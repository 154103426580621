:root {
  --vpHeight: 1000px;
  --height-menu: 8vh;
}

* {
  box-sizing: border-box;
}
.menu {
  color: var(--azul-faw);
  font-family: var(--main-font);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  height: 8vh;
  max-height: 65px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 3px 0 15px 0 rgb(0,0,0,.35);
  padding-left: 2%;
  padding-right: 2%;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: backdrop-filter 0.5s ease, background-color 0.5s ease,
    color 0.5s ease;
}

/* .seccion__productos,
.seccion__prodcaracteristica,
.seccion__prodgaleria,
.seccion__aboutimeline,
.seccion__aboutcompania {
  backdrop-filter: blur(20px);
  background-color: rgba(21, 21, 21, 0.5);
  opacity: 1;
  color: #ffffff;
}

.seccion__postventa,
.seccion__inicio,
.seccion__footer,
.seccion__aboutportada,
.seccion__prodportada,
.seccion__contacto,
.seccion__prodcontacto {
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.6);
} */

.menu-list-container {
  margin: 0;
  padding: 0;
}

.menu-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem; 
}

.menu-list:hover > .a-opciones:not(:hover){
  color: var(--gris-faw-mid);
}

.menu-list-container a {
  color: inherit;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.menu-list-container .a-opciones:hover{
  color: inherit;
}


.logotipo {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--azul-faw);
  margin-right: auto;
}
.logotipo span {
  padding: 5px;
  color: var(--blue-faw);
  font-weight: 600;
}

.logo-faw {
  margin-right: 4%;
  width: 50px;
  /* height: 36px; */
}
.faw-title {
  white-space: nowrap;
  font-size: 1.5em;
  font-family: var(--main-font);
}

.btn-cotizar {
  background: var(--azul-faw);
  font-family: var(--main-font);
  color: white;
  padding: .5em 1em;
  border: none;
  border-radius: .25rem;
  transition: all 150ms ease;
}

.btn-cotizar:hover {
  border: 2px solid var(--azul-faw);
  background-color: var(--gris-faw-light);
  color: var(--azul-faw);
  transform: scale(1.1);  
}

.btn-cotizar:active {
  color: white;
  border: none;
  background-color: var(--warm-color-faw);
  transform: scale(0.95);
  transition: all 250ms cubic-bezier(0,1.1,.7,.9);
}


/* Toggle Menu */

.nav-btn {
  cursor:pointer;
  background: transparent;
  border: none;
  outline: none;
  color: inherit;
  display: none;
  transform: scale(1.15);
}

.nav-close-btn-init {
  display: none;
}

.nav-close-btn {
  display: block;
  position: absolute;
  top: 8vh;
  left: 0;
  width: 100vw;
  height: 95vh;
  z-index: -10000;
  background-color: rgb(0,0,0,.45); 
  transition: all 300ms cubic-bezier(0,.95,.45,1.01)
}

/* Empieza Menu responsive */
@media screen and (max-width: 1100px) {

  .menu-list-container {
    position: absolute;
    top: 10vh;
    right: 6vw;
    min-width: 280px;
    max-height: 300px;
    width: 30vw;
    height: 40vh;
    margin: 0;
    visibility: hidden;
    border-radius: 2rem;
    background-color: rgb(255,255,255);
    box-shadow: -2px 0 15px -5px rgba(0, 0, 0, 0.25);
    color: var(--azul-faw);
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
    transform: translate(40vw,-40vh) scale(0);
    opacity: 0;
    transition: transform 300ms cubic-bezier(0,.95,.45,1.01), opacity 1ms cubic-bezier(0,1.15,0,.93)
  }

  .menu-list {
    padding-top: 10%;
    padding-bottom: 10%;
    flex-direction: column;
    justify-content: flex-start;
  }

  .menu-list a {
    height: 100%;
    font-size: 1.25rem;
  }

  .responsive-nav-bar {
    transform: none;
    visibility: visible;
    opacity: 1;
  }

  .nav-btn {
    display: block;
    z-index: 1000000;
  }

  .btn-cotizar {
    order: -1;
  }
}

@media (max-width: 650px) {
  .menu {
    height: 6.5vh;
    display: flex;
    flex-direction: row;
    transition: all 150ms ease;
  }

  .menu-list-container {
    width: 50vw;
  }

  .logotipo {
    margin-right: auto;
  }

  .logo-faw {
    width: 32px;
  }

  .faw-title {
    font-size: 1rem;
  }

  .nav-close-btn {
    position: absolute;
    top: 6.5vh;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}


@media (max-width: 500px) {
  .menu-list-container {
    right: 50%;
    transform: translateX(50%);
    width: 80vw;
  }

  .menu-list {
    padding: 0;
    padding-top: 10%;
    padding-bottom: 10%;
    justify-content: center;
  }
}
@media (max-width: 400px) {
  .menu-list-container {
    right: 50%;
    transform: translateX(50%);
    width: 50%;
  }

  .menu-list {
    padding: 0;
    padding-top: 10%;
    padding-bottom: 10%;
    justify-content: center;
  }
}